<template>
    <v-container class="pa-4 py-8">
        <div v-if="!showAddEditComponent">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
         <router-link :to="{name: 'ViewAllKRICoverage'}"><v-icon>mdi mdi-arrow-left</v-icon></router-link><v-icon class="ml-2">mdi-chart-bar-stacked</v-icon> View All KRI's

            <span class="grey--text text--lighten-1">(
              <span v-if="returnORMPFilter">{{deptFilter}}</span> 
              <span v-else>{{$store.getters['useraccount/isAuthenticated'].department}}</span>)
            </span>
          <div class="float-right row mr-2">
            <!-- <v-select
                :items="returnDept"
                v-model="deptFilter"
                label="Select Department"
                class="mr-4 col"
                hide-details
                outlined
                style="width: 200px;"
                v-show="returnORMPFilter"
                dense
                >
            </v-select>   -->
            <!-- <router-link :to="{name: 'CreateORMPRow', params: {id: this.$route.params.id}}" tag="button">
              <v-btn
                color="primary"
                dark
                class="mb-2 col"   
                >
                <v-icon>mdi-plus</v-icon>
                New ORMP ROW
              </v-btn>
            </router-link> -->
          </div>
        </div>        

        <v-data-table
          :headers="headers"
          :items="returnORMPs"
          sort-by="calories"
          class="elevation-1"
          single-expand
          show-expand
          item-key=".key"
          >

          <template v-slot:expanded-item="{ item }">
                <td colspan="6"  class="grey lighten-4">
                    <v-row class=" white--text grey darken-3 mt-4">
                        <div class="col-2 ml-2">KRI (Key Risk Indicator)</div>
                        <div class="col ml-2">REPORTS</div>
                    </v-row>
                    <v-row class="full-width " v-show="returnKRIReports(item['.key'])" v-for="(KRI,idx) in returnKRIReports(item['.key'])" :key="KRI['.key']">
                    <div class="col-2 text-title">
                        <v-card class="elevation-0 font-weight-bold primary--text d-flex align-center" height="100%">
                            <div class="text-center pa-2 mx-auto">
                                <v-chip class="ma-2">{{idx+1}}</v-chip> <br>
                                {{KRI.KRI}} <br>
                                <v-btn color="grey" icon small class="mt-2 ml-2" @click="editKRI(item,KRI)">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </v-card>
                    </div>
                   

                    <v-data-table
                        class="col ma-3"
                        :headers="returnKRIHeaders(KRI.TotalX)"
                        :items="KRI.Reports ? KRI.Reports : []"
                        item-key=".key"
                        full-width
                        hide-default-footer
                        :loading="loading"
                    >
                        <template v-slot:item.Description="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.Description"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="closeEditDialog(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.Description }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.Description"
                                class="mt-4"
                                outlined
                                label="Description"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>  
                        <template v-slot:item.Objective="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.Objective"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="closeEditDialog(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.Objective }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.Objective"
                                class="mt-4"
                                outlined
                                label="Objective"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>  
                        <template v-slot:item.Threshold="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.Threshold"
                            @save="save(props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="closeEditDialog"
                            large
                            persistent
                            >
                            {{ props.item.Threshold }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.Threshold"
                                class="mt-4"
                                outlined
                                label="Threshold"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>       
                        <template v-slot:item.TotalIncidents="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.TotalIncidents"
                            @save="save(props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="closeEditDialog"
                            large
                            persistent
                            >
                            {{ props.item.TotalIncidents }}
                            <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.TotalIncidents"
                                  label="Total Incidents"
                                  type="number"
                                  class="mt-4"
                                ></v-text-field>    

                            </template>
                            </v-edit-dialog>
                        </template>  
                        <template v-slot:item.TotalX="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.TotalX"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="closeEditDialog(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.TotalX }}
                            <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.TotalX"
                                  :label="KRI.TotalX"
                                  type="number"
                                  class="mt-4"
                                ></v-text-field>                                
                            </template>
                            </v-edit-dialog>
                        </template> 
                        <template v-slot:item.KRI_TrendQ1="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.KRI_TrendQ1"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="closeEditDialog(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.KRI_TrendQ1 }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.KRI_TrendQ1"
                                class="mt-4"
                                outlined
                                label="KRI Trend Q1"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>   
                        <template v-slot:item.KRI_TrendQ2="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.KRI_TrendQ2"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="closeEditDialog(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.KRI_TrendQ2 }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.KRI_TrendQ2"
                                class="mt-4"
                                outlined
                                label="KRI Trend Q2"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>  
                        <template v-slot:item.KRI_TrendQ3="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.KRI_TrendQ3"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="closeEditDialog(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.KRI_TrendQ3 }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.KRI_TrendQ3"
                                class="mt-4"
                                outlined
                                label="KRI Trend Q3"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>  
                        <template v-slot:item.KRI_TrendQ4="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.KRI_TrendQ4"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="closeEditDialog(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.KRI_TrendQ4 }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.KRI_TrendQ4"
                                class="mt-4"
                                outlined
                                label="KRI Trend Q4"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>  
                        <template v-slot:item.MonitorToolLink="props">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn  v-bind="attrs" v-on="on" icon color="primary"  @click="openSite(props.item.MonitorToolLink)" v-show="props.item.MonitorToolLink !== '-'">
                                  <v-icon>mdi-link</v-icon>
                                </v-btn>     
                              </template>
                              <span>{{props.item.MonitorToolLink}}</span>
                            </v-tooltip>  
                            <v-edit-dialog
                            :return-value.sync="props.item.MonitorToolLink"
                            @save="save(props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="closeEditDialog"
                            large
                            persistent
                            >
     
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.MonitorToolLink"
                                class="mt-4"
                                outlined
                                label="Monitor Tool Link"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>  
                    </v-data-table>
                    <div v-if="KRI.Reports == null && loading == false" class="text-center pa-4">
                        <div class="grey--text mb-2">There is no KRI Report created.</div>
                        <v-btn color="primary" >Create KRI Report</v-btn>
                    </div>   
                    </v-row>                 
                </td>
          </template>

          <template v-slot:item.actions="{ item }">
            <!-- <router-link :to="{name: 'ViewORMP', params: {id: getID(item)}}">-->
            <v-row>
                <v-btn color="primary" small class="mr-2" @click="addKRI(item)">
                    <v-icon class="mr-2">mdi-plus-box</v-icon>
                    Add KRI
                </v-btn>
              <v-icon
                small
                class="mr-2"
                >
                mdi-delete
              </v-icon>
            </v-row>
          </template>


          <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
                >
                  Reset
              </v-btn> 
          </template>
        </v-data-table>
        </div>
        <div v-else>
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
         <v-btn icon @click="cancelOperation(false)"><v-icon>mdi mdi-arrow-left</v-icon></v-btn><v-icon class="ml-2">mdi-chart-bar-stacked</v-icon> View All KRI's
        </div>
          <AddNewKRI :KRIORMP="selectedKRI_ORMP" :isEdit="isEdit" :forUpdate="forUpdate" :coverageId="coverageId" v-if="selectedKRI_ORMP !== null" @backToAll="cancelOperation"/>
        </div>
    </v-container>
</template>

<script>
import AddNewKRI from '../components/AddNewKRI'
  export default {
    name: 'ViewAllKRI',
    components: {
      AddNewKRI
    },
    data: () => ({
      loading: false,
      deptFilter: 'SYSDEV',
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      ORMPData: [],
      headers: [
        { text: 'Risk Name', value: 'RiskName.RiskName' },
        { text: 'Likelihood', value: 'LikelihoodLevel.Level' },
        { text: 'Impact', value: 'ImpactLevel.Level' },
        { text: 'Vulnerability', value: 'Vulnerability' },
        { text: 'Actions', value: 'actions', sortable: false },
        
      ],
      newORMP: {
              ORMPName: '',
          },
      desserts: [],
      editedIndex: -1,
      updatedIndex: -1,
      editedItem: {},
      updatedItem: {},
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      selectedKRI_ORMP: null,
      isEdit: false,
      forUpdate: null,
      coverageId: null,
      showAddEditComponent: false,
    }),
  

    firestore(){
      return {
        ORMPs: this.$db.collection('KRI-ORMP').where("coverageId", "==", this.$route.params.id),
        ORMPLevels: this.$db.collection('ORMPLevels'),
        Departments: this.$db.collection('Departments'),
        KRI: this.$db.collection('KRI').where("coverageId", "==", this.$route.params.id),
        KRI_REPORTS: this.$db.collection('KRI_REPORTS').where("coverageId", "==", this.$route.params.id),
      }
    },

    computed: {
      returnORMPs(){
        let before = this.ORMPs
        let self = this

        let user = self.$store.getters['useraccount/isAuthenticated']
        
        if(user.department !== 'RISK MANAGEMENT TEAM'){
            return before.filter(a=>{
                return a.Department == user.department
            })          
        }

        if(self.deptFilter !== 'ALL DEPARTMENTS'){
            return before.filter(a=>{
                return a.Department == self.deptFilter
            })
        }

        return before
      },
      returnORMPFilter(){
          if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
              return false
          }
          return true
      },
      returnDept(){
          let db = this.Departments.map(a=>{
              return {
                  text: a.departmentName,
                  value: a.departmentName
              }
          })
          return db
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      returnKRIReports(itemKey){
          console.log(itemKey,'itemKey')
          return this.KRI.map(a=>{
              if(itemKey == a.KRIORMPId){
                  a.Reports = this.KRI_REPORTS.filter(x=>{
                      return x.KRI_Id == a['.key']
                  })
                  return a
              }
          })
      },
      returnKRIHeaders(totalX){
          return [
              { text: 'Description', sortable: true, value: 'Description' },
              { text: 'Objective', sortable: true, value: 'Objective' },
              { text: 'Threshold', sortable: true, value: 'Threshold' },
              { text: totalX, sortable: true, value: 'TotalX' },
              { text: 'Total Incidents', sortable: true, value: 'TotalIncidents' },
              { text: 'Q1', sortable: true, value: 'KRI_TrendQ1' },     
              { text: 'Q2', sortable: true, value: 'KRI_TrendQ2' },
              { text: 'Q3', sortable: true, value: 'KRI_TrendQ3' },
              { text: 'Q4', sortable: true, value: 'KRI_TrendQ4' },  
              { text: 'Trending', sortable: true, value: 'Trending' }, 
              { text: 'Data Provider', sortable: true, value: 'DataProvider' },  
              { text: 'Link', sortable: true, value: 'MonitorToolLink' },   
          ]
      },
      addKRI(KRI_ORMP){
        console.log(KRI_ORMP);
        this.selectedKRI_ORMP = KRI_ORMP
        this.coverageId = this.$route.params.id
        this.showAddEditComponent = true
      },
      editKRI(KRI_ORMP,KRI){
        console.log(KRI_ORMP,'KRI_ORMP');
        console.log(KRI,'KRI');
        let updateReports = {...KRI}
        let Reports = updateReports.Reports
        let map = Reports.map(a=>{
          let obj = {...a}
          let id = obj['.key']
          delete obj['.key']
          return {
            ReportKey: id,
            ...obj
          }
        })

        updateReports.Reports = map

        this.selectedKRI_ORMP = KRI_ORMP
        this.coverageId = this.$route.params.id
        this.showAddEditComponent = true
        this.isEdit = true
        this.forUpdate = updateReports
      },
      initialize () {
        this.desserts = this.ORMPs.filter(a=>{
          return this.deptFilter == a.Department
        })
      },
      cancelOperation(value){
        console.log(value,'value')
        if(value){
          this.showAddEditComponent = false
        } else {
          //confirm modal
          //cancel adding or editing
          //set to null value fields for component
          //hidecomponent
          console.log('CONFIRM NEEDED');


          this.showAddEditComponent = false
        }
        
      },
      save(props){
          // console.log(props,'pprops');
          let report = {...props}
          let key = props['.key']
          let user = this.$store.getters['useraccount/isAuthenticated']
          delete report['.key']


          report.DataProvider = user.displayName
          report.Modified = this.$firebase.firestore.FieldValue.serverTimestamp()
          report.lastModifiedBy = user.email
          this.$db.collection('KRI_REPORTS').doc(key).set(report)
          .then(async()=>{
              await this.$store.dispatch('logs/createNotifs', {collection:'KRI_REPORTS',documentKey:key,module: 'KRI',action: 'Updated a KRI REPORT'})  
              this.$dialog.notify.success(`Success Updated KRI Report`, {
                  position: 'bottom-right',
                  timeout: 3000
              })    
          }).catch(err=>{
              this.$dialog.notify.success(`Error : ${err}`, {
                  position: 'bottom-right',
                  timeout: 3000
              })              
          })            
      },  
      closeEditDialog(){

      },
      open(){
        
      },
      cancel(){
        
      },
      openSite(link){
        console.log(link);
        window.open(`${link}`, "_blank");
      }
    }
  }
</script>

<style scoped>
a:link {
  text-decoration: none;
}

</style>