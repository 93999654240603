<template>
    <v-container class="pa-0">
        <!-- SHOW SELECTED ORMP -->
        <v-card
            class="mx-auto elevation-1 pa-4"
            full-width
        >
            <v-row>
                <v-col>
                    <div>Selected ORMP</div>
                    <p class="text-h6 text--primary  mb-0 ">
                        {{KRIORMP.RiskName.RiskName}}
                    </p>
                </v-col>
                <v-col>
                    <div>Likelihood</div>
                    <p class="text-h6 text--primary  mb-0">
                        {{KRIORMP.LikelihoodLevel.Level}}
                    </p>
                </v-col>
                <v-col>
                    <div>Impact</div>
                    <p class="text-h6 text--primary  mb-0">
                        {{KRIORMP.ImpactLevel.Level}}
                    </p>
                </v-col>
                <v-col>
                    <div>Vulnerability</div>
                    <p class="text-h6 text--primary  mb-0">
                        {{KRIORMP.Vulnerability}}
                    </p>
                </v-col>
            </v-row>
        </v-card>
        <v-card             
            class="mx-auto elevation-1 pa-4 mt-4"
            full-width
        >
            <v-stepper
                v-model="step"
                vertical
                class="elevation-0"
            >
                <v-stepper-step
                    :complete="step > 1"
                    step="1"
                    @click="step = 1"
                >           
                    <h4>KRI Details</h4>
                </v-stepper-step>
                <v-stepper-content step="1">
                    <v-text-field
                        class="mt-4"
                        label="KRI (Key Risk Indicator)"
                        placeholder="Enter Key Risk Indicator"
                        outlined
                        v-model="KRI"
                    ></v-text-field>
                    <v-text-field
                        class="mt-4"
                        label="Total X"
                        placeholder="Enter Total X - Column Name"
                        outlined
                        v-model="TotalX"
                    ></v-text-field>
                    <v-btn
                        class="mt-4"
                        color="primary"
                        @click="step++"
                    >
                        Continue
                    </v-btn>
                </v-stepper-content>
                <v-stepper-step
                    :complete="step > 2"
                    step="2"
                    @click="step = 2"
                    full-width
                >           
                    <h4 class="col">Descriptions, Objectives and Thresholds</h4>
                </v-stepper-step>
                <v-stepper-content step="2">

                    <div v-for="(field, index) in KRILoop" :key="index" :class="index !== 0 ? 'mt-8' : ''">
                        <v-btn
                            @click="deleteInput(index, 'KRILoop')"
                            class="elevation-1 float-right ml-3 mt-2 add-button-position"
                            fab
                            small
                            color="primary"
                            v-if="!isEdit"
                        >
                            <v-icon dark>
                            mdi-delete
                            </v-icon>
                        </v-btn>   
                        <v-btn
                            @click="deleteInputInDB(index, 'KRILoop')"
                            class="elevation-1 float-right ml-3 mt-2 add-button-position"
                            fab
                            small
                            color="primary"
                            v-else
                        >
                            <v-icon dark>
                            mdi-delete
                            </v-icon>
                        </v-btn>       
                        <v-row class="pa-4">
                            <v-textarea
                                :label="'Enter Description #'+(index+1)"
                                textarea
                                filled
                                v-model="field.Description"
                                class="col"
                                hide-details    
                                auto-grow
                            ></v-textarea>    
                            <v-textarea
                                :label="'Enter Objective for Description #'+(index+1)"
                                textarea
                                filled
                                v-model="field.Objective"
                                class="col ml-4"
                                auto-grow
                                hide-details   
                            ></v-textarea>   
                            <v-textarea
                                :label="'Enter Threshold for Description #'+(index+1)"
                                textarea
                                filled
                                v-model="field.Threshold"
                                class="col-12 mt-4"
                                auto-grow
                                hide-details   
                            ></v-textarea>  
                        </v-row>
                        <v-btn color="primary" block @click="addNewField('KRILoop')" v-show="(index+1) == KRILoop.length">
                            <v-icon>mdi-plus</v-icon>
                            Add More Rows
                        </v-btn>
                    </div>
                    <v-row class="mt-8">
                        <v-btn
                            class="mt-4"
                            color="grey"
                            text
                            @click="step--"
                        >
                            Back
                        </v-btn>
                        <v-btn
                            class="mt-4 ml-2"
                            color="primary"
                            @click="saveKRI"
                        >
                            <v-icon class="mr-2">mdi-content-save</v-icon>
                            Save KRI
                        </v-btn>
                    </v-row>
                </v-stepper-content>
            </v-stepper>

        </v-card>
        <!-- SHOW SELECTED ORMP -->

    </v-container>
</template>
<script>
export default {
    props: ['KRIORMP','isEdit','forUpdate','coverageId'],
    data: () => ({
        step: 1,
        KRI: '',
        TotalX: '',
        KRILoop: [
            {
                Description: '',
                Objective: '',
                Threshold: ''
            }
        ],
        KRIUPDATEKEY: null
    }),
    created(){
        console.log(this.isEdit,'isEdit')
        console.log(this.forUpdate,'forUpdate');
        if(this.isEdit){
            this.setupForEditing()
        }
    },
    methods:{
        deleteInput(index, arrayName) {
            this[arrayName].splice(index, 1)
        },
        async deleteInputInDB(index,arrayName) {
            let forDelete = this[arrayName][index]
            console.log(forDelete,'forDelete')
            let self = this
            if(forDelete.ReportKey !== undefined){
                const confirm = await self.$dialog.confirm({
                    text: `This has may have important records already. If you delete this all report inputs of this section will be gone. Do you still want to continue?`,
                    title: `Confirm Delete Report in Database`
                })    
                if(confirm){
                    self.$db.collection('KRI_REPORTS').doc(forDelete.ReportKey).delete()
                    .then(async()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection:`KRI_REPORTS`,documentKey:forDelete.ReportKey,module: 'KRI',action: `Deleted KRI Report.`})  
                        self.$dialog.notify.success(`Deleted KRI Report`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })   
                        self[arrayName].splice(index, 1)   
                        if(self[arrayName].length == 0){
                            self.addNewField(arrayName)
                        }               
                    })
                }            
            } else {
                console.log('delete in ARRAY');
                self[arrayName].splice(index, 1)
                if(self[arrayName].length == 0){
                    self.addNewField(arrayName)
                }    
            }
        },
        addNewField(arrayName) {
          this[arrayName].push({
            Description: '',
            Objective: '',
            Threshold: ''
          })
        },
        async saveKRI(){
            
            let KRI = {
                coverageId: this.coverageId,
                KRIORMPId: this.KRIORMP['.key'],
                KRI: this.KRI,
                TotalX: this.TotalX,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department,
                Modified: null,                
            }
            console.log(KRI,'KRI SAVED');


            let self = this
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to ${this.isEdit ? 'update this' : 'add this new'}  KRI for ${this.KRIORMP.RiskName.RiskName}?`,
                title: `Confirm ${this.isEdit ? 'Update' : 'New'} KRI`
            })

            if(confirm){
                if(self.isEdit){
                    self.$db.collection('KRI').doc(self.KRIUPDATEKEY).update({
                        TotalX: KRI.TotalX,
                        KRI: KRI.KRI,
                        Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
                        ModifiedBy: this.$store.getters['useraccount/isAuthenticated'].email
                    }).then(async()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection:'KRI',documentKey:self.KRIUPDATEKEY,module: 'KRI',action: 'Updated KRI'})  
                        self.$dialog.notify.success(`Updated KRI`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })      

                        self.KRILoop.forEach(({Description,Objective,Threshold,forUpdate,ReportKey})=>{
                            if(!forUpdate){
                                let KRI_REPORT = {
                                    KRI_Id: self.KRIUPDATEKEY,
                                    Description: Description,
                                    Objective: Objective,
                                    Threshold: Threshold,
                                    TotalX: 0,
                                    TotalIncidents: 0,
                                    KRI_TrendQ1: 0,
                                    KRI_TrendQ2: 0,
                                    KRI_TrendQ3: 0,
                                    KRI_TrendQ4: 0,
                                    DataProvider: null,
                                    MonitorToolLink: '-',
                                    CreatedAt: self.$firebase.firestore.FieldValue.serverTimestamp(),
                                    CreatedBy: self.$store.getters['useraccount/isAuthenticated'].email,
                                    Department: self.$store.getters['useraccount/isAuthenticated'].department,
                                    Modified: null,    
                                    coverageId: self.coverageId,                
                                }
                                console.log(KRI_REPORT,'KRI_REPORT')

                                self.$db.collection('KRI_REPORTS').add(KRI_REPORT).then(async (doc)=>{
                                    let REPORTid = doc.id

                                    await self.$store.dispatch('logs/createNotifs', {collection:'KRI',documentKey:REPORTid,module: 'KRI',action: 'Added New KRI Report'})  
                                    self.$dialog.notify.success(`Added New KRI Report`, {
                                        position: 'bottom-right',
                                        timeout: 3000
                                    })   
                                })
                            } else {
                                self.$db.collection('KRI_REPORTS').doc(ReportKey).update({
                                    Description: Description,
                                    Objective: Objective,
                                    Threshold: Threshold,                                    
                                }).then(async ()=>{

                                    await self.$store.dispatch('logs/createNotifs', {collection:'KRI',documentKey:ReportKey,module: 'KRI',action: 'Added New KRI Report'})  
                                    self.$dialog.notify.success(`Added New KRI Report`, {
                                        position: 'bottom-right',
                                        timeout: 3000
                                    })   
                                })                                
                            }


                        })    
                        this.$emit('backToAll',true)                        

                    })
                } else {
                    self.$db.collection('KRI').add(KRI).then(async (doc)=>{
                        let id = doc.id

                        await self.$store.dispatch('logs/createNotifs', {collection:'KRI',documentKey:id,module: 'KRI',action: 'Added New KRI'})  
                        self.$dialog.notify.success(`Added New KRI`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })      

                        self.KRILoop.forEach(({Description,Objective,Threshold})=>{
                                let KRI_REPORT = {
                                    KRI_Id: id,
                                    Description: Description,
                                    Objective: Objective,
                                    Threshold: Threshold,
                                    TotalX: 0,
                                    TotalIncidents: 0,
                                    KRI_TrendQ1: 0,
                                    KRI_TrendQ2: 0,
                                    KRI_TrendQ3: 0,
                                    KRI_TrendQ4: 0,
                                    DataProvider: null,
                                    MonitorToolLink: '-',
                                    CreatedAt: self.$firebase.firestore.FieldValue.serverTimestamp(),
                                    CreatedBy: self.$store.getters['useraccount/isAuthenticated'].email,
                                    Department: self.$store.getters['useraccount/isAuthenticated'].department,
                                    Modified: null,    
                                    coverageId: self.coverageId,                
                                }
                                console.log(KRI_REPORT,'KRI_REPORT')

                                self.$db.collection('KRI_REPORTS').add(KRI_REPORT).then(async (doc)=>{
                                    let REPORTid = doc.id

                                    await self.$store.dispatch('logs/createNotifs', {collection:'KRI',documentKey:REPORTid,module: 'KRI',action: 'Added New KRI Report'})  
                                    self.$dialog.notify.success(`Added New KRI Report`, {
                                        position: 'bottom-right',
                                        timeout: 3000
                                    })   
                                })
                        
                        })    
                        this.$emit('backToAll',true)
                    })
                }
            }
        },
        setupForEditing(){
            let {Reports: Reports, KRI: KRI, TotalX: TotalX} = this.forUpdate
            this.KRILoop = []
            Reports.forEach(({Description,Objective,Threshold,ReportKey})=>{
                this.KRILoop.push({
                    Description: Description,
                    Objective: Objective,
                    Threshold: Threshold,  
                    forUpdate: true,
                    ReportKey: ReportKey                 
                })
            })
            this.TotalX = TotalX
            this.KRI = KRI
            this.KRIUPDATEKEY = this.forUpdate['.key']
            console.log(this.KRIUPDATEKEY,'KRIUPDATEKEY');
        }
    }
}
</script>